<template>
  <div>
    <h1 class="text-2xl mb-6">Rentabilité des articles</h1>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <top-stats-card class="mb-3" />

    <all-posts-table class="mb-3" />

    <grouped-posts-table group_by="redac_id" class="mb-3"></grouped-posts-table>

    <grouped-posts-table group_by="site_id" class="mb-3"></grouped-posts-table>
  </div>
</template>
<script>
import filters from "@/components/audience/renta-par-article/filters/Filters";
import topStatsCard from "@/components/audience/renta-par-article/cards/TopStatsCard";
import allPostsTable from "@/components/audience/renta-par-article/tables/allPostsTable.vue";
import groupedPostsTable from "@/components/audience/renta-par-article/tables/groupedPostsTable.vue";
export default {
  name: "RentaParArticles",
  components: {
    filters,
    topStatsCard,
    allPostsTable,
    groupedPostsTable,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style></style>
