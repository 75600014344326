<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistiques</span>
    </v-card-title>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-container>
    <v-card-text v-else>
      <v-row>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyEur }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">CA</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ ca.value }}</span
                  >
                </template>
                <span>{{ ca.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiSpeedometer }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">RPM</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ rpm.value }}</span
                  >
                </template>
                <span>{{ rpm.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiMonitorEye }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Vues</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ views.value }}</span
                  >
                </template>
                <span>{{ views.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiFormatRotate90 }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Sessions</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ sessions.value }}</span
                  >
                </template>
                <span>{{ sessions.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" md="4">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiMonitorCellphone }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Coût Moyen / article</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ avgCost.value }}</span
                  >
                </template>
                <span>{{ avgCost.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSpeedometer,
  mdiMonitorCellphone,
  mdiMonitorEye,
  mdiCurrencyEur,
  mdiFormatRotate90,
} from "@mdi/js";
import { formatNumberCompact, formatCurrencyCompact } from "@/utils/formatting";

export default {
  name: "TopStatsCard",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiSpeedometer,
        mdiMonitorCellphone,
        mdiMonitorEye,
        mdiCurrencyEur,
        mdiFormatRotate90,
      },
      ca: {
        value: 0,
        raw: 0,
      },
      views: {
        value: 0,
        raw: 0,
      },
      sessions: {
        value: 0,
        raw: 0,
      },
      rpm: {
        value: 0,
        raw: 0,
      },
      avgCost: {
        value: 0,
        raw: 0,
      },
    };
  },
  computed: {
    statsForPeriod() {
      return this.$store.getters["audience/getRentaGlobalStats"];
    },
  },
  watch: {
    statsForPeriod() {
      this.ca.raw = this.statsForPeriod.total_ca;
      this.ca.value = formatCurrencyCompact(this.ca.raw);
      this.views.raw = this.statsForPeriod.total_views;
      this.views.value = formatNumberCompact(this.views.raw);
      this.sessions.raw = this.statsForPeriod.total_sessions;
      this.sessions.value = formatNumberCompact(this.sessions.raw);
      this.rpmcalcul = (this.ca.raw / this.sessions.raw) * 1000;
      this.rpm.raw = `${this.rpmcalcul.toFixed(2)}€`;
      this.rpm.value = this.rpm.raw;
      this.avgCost.raw = formatCurrencyCompact(
        this.statsForPeriod.average_price
      );
      this.avgCost.value = this.avgCost.raw;
    },
  },
};
</script>

<style scoped></style>
