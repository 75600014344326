export function paramsSerializer(params) {
    return Object.keys(params)
      .map(key => {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
  }